









































import { HtmlModal, Scrollbar, SvgImage, TwButton } from '@/app/components';
import store from '@/app/store';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'DisabledBlocksModal',
    props: {
        isFinalised: {
            type: Boolean,
            default: false,
        },
        deletedBlocks: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        HtmlModal,
        SvgImage,
        TwButton,
        Scrollbar,
        ChevronRightIcon,
    },
    setup() {
        return { store };
    },
});
