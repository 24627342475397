



































































































































































































































































































































































































































































































































































import { FormBlock, LoadingCloneModal, Scrollbar } from '@/app/components';
import AlertBanner from '@/app/components/AlertBanner.vue';
import { useAxios, useFeatureFlags } from '@/app/composable';
import store from '@/app/store';
import { S } from '@/app/utilities';
import { localPathValidator, maxLengthValidator, requiredValidator } from '@/app/validators';
import { AccessPolicy } from '@/modules/access-policy/components';
import { AuthzResourceType } from '@/modules/access-policy/constants';
import { AccessLevel, AccessLevelsExtensiveOptions } from '@/modules/access-policy/constants/access-levels.constants';
import { GeneralPolicy } from '@/modules/access-policy/models';
import { RunnerAPI } from '@/modules/apollo/api';
import { WorkflowStatus } from '@/modules/apollo/constants';
import { AssetType, AssetTypeId } from '@/modules/asset/constants';
import { Asset } from '@/modules/asset/types';
import {
    CheckIcon,
    ChevronLeftIcon,
    ChipIcon,
    CubeTransparentIcon,
    DatabaseIcon,
    PencilAltIcon,
    XIcon,
} from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { WorkflowAPI } from '../api';
import {
    ExecutionFramework,
    ExecutionFrameworkWrapper,
    ExecutionLocationType,
    ExecutionStorageLocation,
} from '../constants';

extend('required', requiredValidator);
extend('max', maxLengthValidator);
extend('path', localPathValidator);

type Mode = 'create' | 'clone' | 'edit' | 'view';

export default defineComponent({
    name: 'ConfigureWorkflow',
    metaInfo() {
        return {
            title: `${(this as any).actionText} Data Analytics Pipeline`,
        };
    },
    props: {
        id: {
            type: String,
            required: false,
        },
        mode: {
            type: String as PropType<Mode>,
            default: 'create',
        },
        backTo: {
            type: String,
            default: 'workflows',
        },
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        FormBlock,
        OrbitSpinner,
        Scrollbar,
        AccessPolicy,
        PencilAltIcon,
        ChevronLeftIcon,
        DatabaseIcon,
        ChipIcon,
        CubeTransparentIcon,
        XIcon,
        CheckIcon,
        AlertBanner,
        LoadingCloneModal,
    },
    setup(props, { root }) {
        const user = computed(() => store.state.auth.user);
        const { flag } = useFeatureFlags();
        const isOnPremiseRunnerEnabled = flag('on-premise');

        const mode = ref<Mode>(props.mode);

        const actionText = computed(() => {
            switch (mode.value) {
                case 'view':
                    return 'Info for the';
                case 'clone':
                    return 'Clone';
                case 'edit':
                    return 'Update';
                default:
                    return 'Create';
            }
        });

        const submitActionText = computed(() => {
            switch (mode.value) {
                case 'clone':
                    return 'Clone';
                case 'edit':
                    return 'Update';
                default:
                    return 'Create';
            }
        });
        const accessLevel = ref<AccessLevel>(AccessLevel.Private); //default
        const accessPolicies = ref<any>({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });
        const invalidAssets = ref<{ inputAssets: Asset[]; outputAssets: Asset[] }>();

        const { exec, loading } = useAxios(true);

        const error = ref<any>();

        // reset selected runner in case it does not exist
        const resetRunner = () => {
            if (
                workflow.value.runnerId &&
                runners.value &&
                !runners.value.find((runner: any) => runner.id === workflow.value.runnerId)
            )
                workflow.value.runnerId = null;
        };

        const goBackTo = () => {
            if (props.backTo === 'workflows')
                root.$router.push({ name: props.backTo, query: store.state.queryParams.workflows });
            else if (props.backTo === 'assets')
                root.$router.push({ name: props.backTo, query: store.state.queryParams.assets });
            else root.$router.push({ name: props.backTo });
        };

        if (props.id) {
            exec(WorkflowAPI.getWorkflow(props.id))
                .then((res: any) => {
                    if (mode.value !== 'clone' && res.data?.status === WorkflowStatus.Suspended) {
                        (root as any).$toastr.w(
                            `This analytics pipeline is suspended as some of the used assets have been deleted. You need to edit the pipeline to be able to use this pipeline again.`,
                            'Warning',
                        );
                        goBackTo();
                    }
                    if (res.data?.type !== 'analytics') {
                        (root as any).$toastr.e('The Analytics Pipeline was not found', 'Error');
                        goBackTo();
                    } else {
                        workflow.value = res.data;
                        previousAccessLevel.value =
                            mode.value === 'clone' ? AccessLevel.Private : workflow.value.accessLevel;
                        previousAccessPolicies.value = mode.value === 'clone' ? [] : workflow.value.policies;
                        accessLevel.value = mode.value === 'clone' ? AccessLevel.Private : workflow.value.accessLevel;
                        executionLocation.value = workflow.value.runnerId
                            ? ExecutionLocationType.Local
                            : ExecutionLocationType.Cloud;
                        executionStorageLocation.value =
                            workflow.value.configuration.location ?? ExecutionStorageLocation.Cloud;
                        resetRunner();
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        switch (e.response.status) {
                            case 403:
                                (root as any).$toastr.e('You do not have access to the specific pipeline', 'Error');
                                break;
                            case 404:
                                (root as any).$toastr.e('The Analytics Pipeline was not found', 'Error');
                                break;
                        }
                        goBackTo();
                    }
                    error.value = e;
                });

            exec(WorkflowAPI.getInputAssets(props.id))
                .then((res: any) => (inputAssets.value = res.data))
                .catch((e) => (error.value = e));

            if (mode.value !== 'clone') {
                exec(WorkflowAPI.getProvenanceAssets(props.id))
                    .then((res: any) => (provenanceAssets.value = res.data))
                    .catch((e) => (error.value = e));
            }
        }

        const frameworks = ExecutionFrameworkWrapper.all();
        const workflow = ref({
            id: '',
            name: '',
            description: '',
            framework: null,
            runnerId: null,
            configuration: { location: ExecutionStorageLocation.Cloud },
            imageVersion: null,
            policies: [],
            accessLevel: accessLevel.value,
            platform: undefined,
            createdById: null,
        });

        const isUserWorkflowCreator = computed(() => user.value.id === workflow.value.createdById);

        const workflowRef = ref<any>(null);
        const executionLocation = ref<string>(ExecutionLocationType.Cloud);
        const executionStorageLocation = ref<ExecutionStorageLocation>(ExecutionStorageLocation.Cloud);
        const runners = ref<any[] | null>(null);
        const provenanceAssets = ref<Asset[]>([]);
        const inputAssets = ref<Asset[]>([]);
        const previousAccessLevel = ref<string | null>(null);
        const previousAccessPolicies = ref<any>([]);
        const loadingCloning = ref<boolean>(false);
        const clonePipelinePayload = ref<any>(null);

        // Load registered runners
        if (isOnPremiseRunnerEnabled.value)
            exec(RunnerAPI.all())
                .then((response: any) => {
                    if (response && response.data) {
                        runners.value = response.data;
                        resetRunner();
                    }
                })
                .catch((e) => (error.value = e));

        const isWorkflowComplete = computed(() => {
            if (
                !R.isEmpty(workflow.value.name.trim()) &&
                !R.isEmpty(workflow.value.description.trim()) &&
                !R.isNil(workflow.value.framework) &&
                !R.isEmpty(workflow.value.framework)
            ) {
                if (executionLocation.value === ExecutionLocationType.Cloud) {
                    return true;
                }
                if (workflow.value.runnerId) {
                    return true;
                }
            }

            return false;
        });

        const clearExecutionLocation = () => {
            if (workflow.value.framework === ExecutionFramework.spark3) {
                executionLocation.value = ExecutionLocationType.Cloud;
                executionStorageLocation.value = ExecutionStorageLocation.Cloud;
            }
            workflow.value.runnerId = null;
        };

        // TODO: hide for now when new APs are added for workflows
        // const getAccessPoliciesJSON = () => {
        //     const json = [];

        //     if (accessLevel.value === AccessLevel.OrganisationLevel) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(true, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //     } else if (accessLevel.value === AccessLevel.SelectiveSharing) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(true, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //         accessPolicies.value.policies.forEach((exceptionPolicy: ExceptionPolicy) => {
        //             json.push(exceptionPolicy.toJSON());
        //         });
        //     } else {
        //         accessPolicies.value.policies.forEach((policy: ExceptionPolicy) => {
        //             json.push(policy.toJSON());
        //         });
        //     }

        //     return json;
        // };

        const clonePipeline = () => {
            loadingCloning.value = true;
            exec(WorkflowAPI.clone(props.id as string, clonePipelinePayload.value))
                .then(() => {
                    loadingCloning.value = false;
                    (root as any).$toastr.s(`Analytics pipeline has been cloned`, 'Success');
                    goBackTo();
                })
                .catch((e) => {
                    loadingCloning.value = false;
                    if (e.response && e.response?.status === 403 && e.response?.data?.message === 'Locked') {
                        (root as any).$toastr.e(
                            `Failed to clone this pipeline as it's locked for use by another user.`,
                            'Error',
                        );
                    } else {
                        (root as any).$toastr.e(`Failed to clone analytics pipeline`, 'Error');
                    }
                });
        };

        const save = async () => {
            const valid = await workflowRef.value.validate();
            if (valid) {
                // keep previous access level and policies before updates
                previousAccessLevel.value = R.clone(workflow.value.accessLevel);
                previousAccessPolicies.value = R.clone(workflow.value.policies);

                workflow.value.accessLevel = accessLevel.value;
                // workflow.value.policies = getAccessPoliciesJSON() as [];
                // load workflow if id provided
                if (props.id) {
                    const data: any = {
                        name: workflow.value.name,
                        description: workflow.value.description,
                        runnerId: workflow.value.runnerId,
                        framework: workflow.value.framework,
                        configuration: {
                            ...workflow.value.configuration,
                        },
                        imageVersion: workflow.value.imageVersion,
                        addPolicies: accessPolicies.value?.policies?.add,
                        removePolicies: accessPolicies.value?.policies?.remove,
                        accessLevel: workflow.value.accessLevel,
                        platform: workflow.value.platform,
                    };
                    if (mode.value === 'clone') {
                        data.accessLevel = AccessLevel.Private;
                        clonePipelinePayload.value = data;
                        clonePipeline();
                    } else {
                        await exec(WorkflowAPI.update(props.id, data))
                            .then(() => {
                                (root as any).$toastr.s(
                                    `Analytics pipeline '${S.sanitizeHtml(workflow.value.name)}' has been updated`,
                                    'Success',
                                );
                                goBackTo();
                            })
                            .catch((e: any) => {
                                if (e?.response?.status) {
                                    if (e.response.status === 400) {
                                        error.value = e;
                                        invalidAssets.value = e.response.data;
                                    } else if (e.response.status === 403 && e.response?.data?.message === 'Locked') {
                                        (root as any).$toastr.e(
                                            'The analytics pipeline is locked by another user',
                                            'Error',
                                        );
                                    }
                                }

                                // on error reset workflow access level and policies
                                workflow.value.accessLevel = previousAccessLevel.value as AccessLevel;
                                workflow.value.policies = previousAccessPolicies.value;
                            });
                    }
                } else {
                    if (accessPolicies.value?.policies?.add)
                        workflow.value.policies = accessPolicies.value.policies.add; // add access policies upon creation
                    await exec(WorkflowAPI.create(workflow.value as any))
                        .then((res: any) => {
                            (root as any).$toastr.s(
                                `Analytics pipeline '${S.sanitizeHtml(workflow.value.name)}' has been created`,
                                'Success',
                            );
                            root.$router.push({ name: 'workflow-designer:edit', params: { id: res.data.id } });
                        })
                        .catch((e) => {
                            error.value = e;
                        });
                }
            }
        };

        const onCloudExecution = computed(
            () => !workflow.value.framework || workflow.value.framework === ExecutionFramework.spark3,
        );

        const editWorkflow = () => {
            // just for updating url
            root.$router.push({ name: 'workflow', params: { id: workflow.value.id, mode: 'edit' } });
            // re-render component
            mode.value = 'edit';
        };

        const back = () => root.$router.push({ name: 'workflows', query: store.state.queryParams.workflows });

        const assetTypeMap = {
            [AssetTypeId.Model]: {
                type: AssetType.Model,
                class: 'bg-purple-100 text-purple-600',
                icon: ChipIcon,
            },
            [AssetTypeId.Result]: {
                type: AssetType.Result,
                class: 'bg-indigo-100 text-indigo-600',
                icon: CubeTransparentIcon,
            },
            [AssetTypeId.Dataset]: {
                type: AssetType.Dataset,
                class: 'bg-orange-100 text-orange-800',
                icon: DatabaseIcon,
            },
        };

        const customError = computed(() => {
            if (error.value?.response?.status === 403) {
                return { title: 'Access Forbidden!', message: 'You do not have access to edit the specific asset.' };
            } else if (error.value?.response?.status === 400 && !error.value.response?.data?.valid) {
                return {
                    title: 'Access Policy Restriction!',
                    message:
                        'Updating the access level of the specific analytics pipeline is not allowed at the moment. Please change first the access level of the <a href="#dataAssets" class="underline">related data assets</a> (either used as the pipeline\'s input or created during the pipeline\'s execution), ensuring that it is compatible with (i.e. more permissive than) the access level you eventually want for this pipeline.',
                };
            }
            return { title: 'An error has occurred!', message: error.value?.response?.data?.message };
        });

        const isAffectingInputAP = (asset: Asset) => {
            return !R.isNil(invalidAssets.value?.inputAssets.find((obj) => obj.id === asset.id));
        };
        const isAffectingOutputAP = (asset: Asset) => {
            return !R.isNil(invalidAssets.value?.outputAssets.find((obj) => obj.id === asset.id));
        };

        onUnmounted(async () => {
            if (props.id) await exec(WorkflowAPI.release(props.id as string)).catch(() => null);
        });

        onMounted(async () => {
            if (props.id) {
                await exec(WorkflowAPI.release(props.id as string)).catch(() => null);
                try {
                    await exec(WorkflowAPI.lockPipeline(props.id as string));
                } catch (e: any) {
                    if (e?.response?.status) {
                        if (e.response.status === 403 && e.response?.data?.message === 'Locked') {
                            (root as any).$toastr.w('The anlytics pipeline is locked by another user', 'Warning');
                        } else {
                            error.value = e;
                        }
                    }
                }
            }
        });

        return {
            isWorkflowComplete,
            workflowRef,
            workflow,
            loading,
            error,
            frameworks,
            goBackTo,
            save,
            runners,
            executionLocation,
            executionStorageLocation,
            clearExecutionLocation,
            ExecutionFramework,
            onCloudExecution,
            actionText,
            ExecutionLocationType,
            submitActionText,
            editWorkflow,
            back,
            inputAssets,
            provenanceAssets,
            assetTypeMap,
            AssetType,
            AccessLevel,
            AccessLevelsExtensiveOptions,
            isUserWorkflowCreator,
            accessLevel,
            user,
            previousAccessLevel,
            accessPolicies,
            loadingCloning,
            clonePipeline,
            customError,
            invalidAssets,
            isAffectingInputAP,
            isAffectingOutputAP,
            AuthzResourceType,
            isOnPremiseRunnerEnabled,
        };
    },
});
